import React from 'react';
import { GoogleSpreadsheet } from "google-spreadsheet";

import style from './index.module.scss';

import {
  BrowserView,
  MobileView
} from "react-device-detect";

const SPREADSHEET_ID = "1sv3Ps5azUkvLsfaSPMQ4G-KhyvNE5-a4pUwTppLnm5Y";
const SHEET_ID = "0";
const CLIENT_EMAIL = "iboughtgme@eng-serenity-160217.iam.gserviceaccount.com";
const PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDxDQiINTS+0z+W\n7ZQykDzJoF/tpkiZtdS9dV6doJYH6+PqfhZRInJN51H9cmCu3gfPAuWQD6yKVjHv\nFX+eG2Uyr8PP9uNosZZueCc+Qrh5xXbF8ynIsV20C1TdN8zdhHUBnZbHopnSeBES\nuZRfU/DqvqUsy8h6kdROttv9dNBtBqZNvN+ATuXxMItjCq6Usj0x1oq535W9Lj72\n8bRIDIyTXyy2IreuxsR1RkEi4/sZTGYp3DxuHg3Rq6mtoBIqlg0nNbdg2btHiqHX\n0dUKkH0H+exCW0myZnI0ca3WRRmfmP1iDrSXcWKbfdCZptfuVettXtQFDE+0jpEb\neIm/f7uXAgMBAAECggEAHPjpDp3+gfUYDobtqgomFEHfZcv3q+nr8P2baqyze65B\njZysCAJw5KYp96F4t2veWmZJuz/3CAWGKOtXlGRMjIQNsABHovy9TPfKF7SjQZeX\nSAGLAyE2Vv6Jq7EaQYG7In+TcNk9Tcu6mZqOl8qaCUzyw2iW5wyCDRfzZwpURtYr\nCBDAA3ilM07nHujSNZqcJMMdqDxyfFd55o6T+cS7ap2UEuRk5aF/WEfo1Yf9uJgC\nEP6l/juyU/xZR7QndLaJC/EG+uxlSAJknkMuJ4m2zVL20wArnCtUxbzqe5m0Md6+\nfcwaNOfk+7JRVU2qsAlmZZS5xVKqVJ57GSrJvYP08QKBgQD/UZzhDx5iqnIK953z\nui6jhzTHsSoITEqM9lh2l3RGNPOkygobyMGIAeQcdgNzud+Y2AMa881vC8GiAr0Z\n1dYK5169GcEGlQfK/3dHIjCHIgOdIqQcY8YBPlW0H6IjUyM60nPC++F7xoYp0m9g\nTGM+76Q6mDVO1HR7ngd+5lHFfwKBgQDxsazgofuMik7A+FhhmHsHTvEcyWV7ebsH\nxJawThC2cu1xOOCvEpCQJQSz87tn7TKKiGRTyojC7E6K9uv3ntEtKnwtE4owJoNW\nvhm8hXuKrjuX9PI1hL2YaepwUgtCnyx2IvjV0faasszp1lyMvArW52Gi4Ygz9KXX\nffcbSIiF6QKBgQDxt/SMZmHgC3pYw8F4pAXPsAvaGvKuVHwoJHnwHMdqSGt60gTr\n9Q60kwXBpED0aT81VxzpgXQ286xok6swMHCqdg4NfbpvXty35VCpucazyRIezFMO\nxa8KMle1ZlV8mBM8d049hXYeVv9hzObBBtDXHs4e40dk0GNPPXffPnKgRQKBgQCu\nVpQ2AIG7kXRXO4w88ckkr2u6cQdzto4Tf1mW2OizQllFx1pCzqhaYWlwCIqQ34cK\naHG+AyT9PYk2MgBpwDRwWOnzddnC+7TXWA6mbr57fUxdQwGHWzWLgDLsTDH1H23p\n1TYavfYdBoZK0iY2GdnTxFkoLXiayYjq1ODu3DYmKQKBgQDG30aNmUHGTcvOiFsM\nVyIwo6A9HE7hAI8esrUmPL2Av4G7F3foUzZlUGJQ2qNxqznu8nze464HYF8G22KC\nY/d6Gw4OaT/ANfaBSBYx/TEd98zE+NHSs5pyqvFUG1c2wycOxvofE/0TaYLy9JOm\nc+X1DKytjDap0KnkNEruJPpC9w==\n-----END PRIVATE KEY-----\n";

class EmailSignUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: "Invalid Email",
      email: "",
      invalid: true,
      submitted: false
    }
    this.doc = new GoogleSpreadsheet(SPREADSHEET_ID);
  }

  handleOnChange = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ( re.test(email.target.value) ) {
      this.setState({
        invalid: false,
        email: email.target.value,
        message: ""
      })
    } else {
      this.setState({
        invalid: true,
        email: email.target.value,
        message: "Invalid Email"
      })
    }
  }


  submitEmail = () => {
    if (!this.state.invalid && !this.state.submitted) {
      const newRow = { Email: this.state.email };
      this.appendSpreadsheet(newRow);

      this.setState({
        invalid: true,
        email: "",
        message: "Welcome to the Revolution",
        submitted: true
      })
    }
  }

  async appendSpreadsheet(row) {
    try {
      await this.doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      await this.doc.loadInfo();

      const sheet = this.doc.sheetsById[SHEET_ID];
      const result = await sheet.addRow(row);
    } catch (e) {
      console.error('Error: ', e);
    }
  };


  render() {
    return (
      <div className={style.EmailSignUp}>
        <div className={style.EmailSignUpDiv}>
          Sign your name see where this goes
        </div>
        <div className={style.EmailSignUpMessage}>
          {this.state.message}
        </div>
        <BrowserView>
          <div className={style.EmailSignUpCont}>
            <div>
              <input type={"text"}
                     placeholder={"Email"}
                     className={style.EmailSignUpInput}
                     value={ this.state.email }
                     onChange={ this.handleOnChange.bind(this) }/>
            </div>
            <div className={style.EmailSignUpButton}
                 onClick={this.submitEmail.bind(this)}>
              Sign Up
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className={style.EmailSignUpContMobile}>
            <div>
              <input type={"text"}
                     placeholder={"Email"}
                     className={style.EmailSignUpInputMobile}
                     value={ this.state.email }
                     onChange={ this.handleOnChange.bind(this) }/>
            </div>
            <div className={style.EmailSignUpButtonMobile}
                 onClick={this.submitEmail.bind(this)}>
              Sign Up
            </div>
          </div>
        </MobileView>
        <div>
          Email me privately if you want, I enjoy deep conversations: romieklund@gmail.com
        </div>
      </div>
    );
  }
}

export default EmailSignUp;