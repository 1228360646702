import React from 'react';
import style from './index.module.scss';

class TitleBar extends React.Component {
  render() {
    return (
      <div className={style.TitleBar}>
        I BOUGHT GME
      </div>
  	);
  }
}

export default TitleBar;