import React from 'react';
import './App.css';

import EmailSignup from './Components/EmailSignup';
import Manifesto from './Components/Manifesto';
import StatusBar from './Components/StatusBar';
import TitleBar from './Components/TitleBar';

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <TitleBar />
        <StatusBar />
        <Manifesto />
        <EmailSignup />
      </div>
    );
  }
}

export default App;
