import React from 'react';
import style from './index.module.scss';

class Manifesto extends React.Component {
  render() {
    return (
      <div className={style.Manifesto}>
        <h1>A Declaration of Independence</h1>
        <p className={style.Idiots}>[Note we're all just idiots, there’s no movement]</p>
        <p className={style.Paragraph}>If you're a hedge fund looking to short this stock your attention is directed <a href={"https://cdn.shopify.com/s/files/1/1061/1924/products/Poop_Emoji_7b204f05-eec6-4496-91b1-351acc03d2c7_large.png?v=1571606036"}>here</a>. For all the other diamond hands, here we are. Bruised, battered but still here.</p>
        <p className={style.Quote}>"To Win a War, You Must Lose a Battle" - Sun Tzu</p>
        <p className={style.Paragraph}>For decades retail traders were the scum, the bottom of the barrel, uncomparable to the <span className={style.Emphasis}>"smart money"</span>. We warned them this would happen, occupied their lands in 2011, for all our fallen brothers, respect ✌️. We thought they'd learn their lesson after 2008, after screwing around with our retirements like it was their own personal casino, but soon they were back to their <a href={"https://knowledge.wharton.upenn.edu/article/cdos-are-back-will-they-lead-to-another-financial-crisis/"}>old ways</a> meddling with affairs in congress. A straight wall street to the government road of <a href={"https://www.investopedia.com/news/26-goldman-sachs-alumni-who-run-world-gs/"}>succession</a> was paved. Hegies acted like they were our kings and expected us to bow. I guess they forgot about 1776.</p>
        <p className={style.Paragraph}>Actually, everything seemed just fine for a bit there. We got along with our lives. The stock market seemed to be loving it, see it's easy when you can <a href={"https://www.economicshelp.org/blog/4682/economics/problems-of-quantitative-easing/"}>print all the money</a> you want in the world and keep interest rates (for the hegies) at zero percent. See, you figured that by buying the media companies you could control the <a href={"https://prospect.org/economy/why-hedge-funds-shouldnt-own-the-news/#:~:text=Today%2C%20the%20largest%20U.S.%20newspaper,or%20indebted%20to%20hedge%20funds."}>narrative</a>, keep us all distracted while you did your dirty deeds. You seem to forget that we stopped trusting those a long time ago. You can't pull the curtain over our heads anymore, internet, decentralization, blockchain it's coming like a storm, and the reality is that y'all better hold on, cause it's coming.</p>
        <p className={style.Paragraph}>It didn't start with GME, it certainly was the shot heard around the world. The beginning of a new age, a cumulative sum total of <a href={"https://www.reuters.com/article/us-retail-trading-shortbets/losses-on-short-positions-in-u-s-firms-top-70-billion-ortex-data-idUSKBN29X1SW"}>70 billion</a> lost (and counting). See that was what they never understood, it wasn't about the money it was about principle. And even then they fought back, changed the rules of the game from under us. Told us that the system couldn't handle us, well the system wasn't built for us, so duh. Just a convenient excuse to get out from under their own failures. Because if they played fair, if they wanted a free and open market, they would have lost. And they simply can't accept that. Talk about sore losers, let em’ go suck on their warheads, the real war's coming.</p>
        <p className={style.Paragraph}>See this isn't over, it's not the last stand, we ain't gonna just sit by and haku-na-ma-tata our way through life while you willingly drag us through the mud and destroy our financial system. We tasted fresh meat, and we can't get enough of it. GME isn't a stock, it's a way of life. And we thank you for lighting that fire. Now respectfully <span className={style.Emphasis}>GTFO</span> 🔥🔥🔥.</p>
        <p className={style.Signature}>Romi Eklund</p>
        <p className={style.Note}>- An Idiot who's acting on his own behalf</p>
      </div>
    );
  }
}

export default Manifesto;